.wrapper {
  gap: 12px;
  padding: 12px 16px;
}

.wrapper--ios .textarea {
  padding: 16px;
  min-height: 84px;
}

.chevron {
  color: var(--tgui--secondary_hint_color);
  pointer-events: none;
}

.inputWrapper {
  flex: 1 1 0;
  appearance: none;
  width: 100%;
  border: none;
  color: var(--tgui--text_color);
  outline: none;
  padding: 0px;
  margin: 0px;
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.input {
  flex: 1 1 0;
  appearance: none;
  width: 100%;
  border: none;
  background: none;
  color: var(--tgui--text_color);
  outline: none;
  padding: 0px;
  margin: 0px;
  resize: none;
  min-height: 80px;
}

.input::placeholder {
  color: var(--tgui--secondary_hint_color);
}
