.wrapper {
  display: flex;
  flex-direction: row;
  padding: 4px 12px;
  gap: 8px;
  background-color: #00000040;
  backdrop-filter: blur(40px);
  border-radius: 16px;
  text-transform: uppercase;
  align-items: center;
  color: white;
  font-weight: bold !important;
  margin-top: 8px;
}

.green {
  background-color: var(--tgui--green);
}

.orange {
  background-color: #FFAA00;
}

.red {
  background-color: var(--tg-theme-destructive-text-color);
}

.grey {
  background-color: #999;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 16px;
}
