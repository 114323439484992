.wrapper {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  gap: 8px;
  flex-wrap: wrap;
}

.chip {
  /* -webkit-box-shadow: 8px 8px 21px -13px rgba(0, 0, 0, 1); */
  /* -moz-box-shadow: 8px 8px 21px -13px rgba(0, 0, 0, 1); */
  /* box-shadow: 8px 8px 21px -13px rgba(0, 0, 0, 1); */
  /* backdrop-filter: blur(10px); */
  h6 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    div {
      padding-right: 8px;
    }
  }
}
