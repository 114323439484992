.wrapper {
  min-width: 256px;
  max-width: 256px;
  width: 256px;
  box-shadow: none !important;
}

.content {
  padding: 0px 16px 16px;
}

.title {
  text-wrap: initial;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: bold;
}

.dateTime {
  text-wrap: initial;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2;
  -webkit-box-orient: vertical;
  color: var(--tgui--subtitle_text_color);
}
