.padding {
  padding: 16px;
  padding-top: 24px;
  background-color: #00000000;
  backdrop-filter: blur(40px);
}

.content {
  padding-top: 8px;
}

.loading {
  align-items: center !important;
  text-align: center;
  padding-top: 48px;
}

.emptyWrapper {
  padding-top: 48px;
  width: 100%;
  text-align: center;
  gap: 24px;
  display: flex;
  flex-direction: column;
}

.empty {
  color: var(--tgui--secondary_hint_color);
  text-align: center;
}

.after {
  color: var(--tgui--secondary_hint_color);
}
