.wrapper {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: scroll;
  white-space: nowrap;
  gap: 24px;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  filter: drop-shadow(0 0 30px #0002);
}

.wrapper::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}
