.modal {
  z-index: 1000;
}

.padding {
  margin-top: -10px;
  padding: 0px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: stretch !important;
}

.button {
  width: 100%;
}