.card {
  width: 100%;
}

.imageAndTagsWrapper {
  position: relative;
}

.overImageWrapper {
  position: absolute;
  right: 12px;
  top: 12px;
}

.chips {
  /* position: absolute; */
  /* bottom: 16px; */
  /* left: 16px; */
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  row-gap: 8px;
  flex-wrap: wrap;
  /* max-width: 300px; */
  justify-content: left;
}

.chip {
  /* -webkit-box-shadow: 8px 8px 21px -13px rgba(0, 0, 0, 1); */
  /* -moz-box-shadow: 8px 8px 21px -13px rgba(0, 0, 0, 1); */
  /* box-shadow: 8px 8px 21px -13px rgba(0, 0, 0, 1); */
  /* backdrop-filter: blur(10px); */
  h6 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    div {
      padding-right: 8px;
    }
  }
}

.title {
  color: var(--tgui--accent_text_color);
}

.scheduledAt {
  color: var(--tgui--subtitle_text_color);
}

.scheduledAtToday {
  color: var(--tgui--secondary_code_highlight);
}

.scheduledAtTomorrow {
  color: var(--tgui--tertiary_code_highlight);
}

.content {
  padding: 24px;
  text-align: left;
}
