.game {
  width: calc(100vw - 20%);
}

.centerPadding {
  align-items: center !important;
  text-align: center;
  padding: 16px;
  padding-top: 24px;
  max-width: 700px;
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.list {
  display: flex;
  flex-direction: column;
}
