.wrapper {
  gap: 12px;
  padding: 12px 16px;
}

.wrapper--ios {
  min-height: 48px;
}

.chevron {
  color: var(--tgui--secondary_hint_color);
  pointer-events: none;
}

.input {
  -webkit-clip-path: none !important;
  clip-path: none !important;
  position: inherit !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.text {
  flex: 1 1 0;
  appearance: none;
  width: 100%;
  border: none;
  color: var(--tgui--text_color);
  outline: none;
}

.placeholder {
  color: var(--tgui--secondary_hint_color);
}
