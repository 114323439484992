.wrapper {
  align-items: center !important;
}

.icon {
  color: var(--tgui--secondary_hint_color);
}

.caption {
  color: var(--tgui--secondary_hint_color);
}
