.padding {
  padding: 16px;
  padding-top: 24px;
}

.content {
  padding-top: 8px;
}

.tabsList {
  margin-bottom: 16px;
}

.emptyWrapper {
  padding-top: 48px;
  width: 100%;
  text-align: center;
}

.counts {
  font-weight: normal;
  opacity: 0.66;
  padding-left: 8px;
}

.empty {
  color: var(--tgui--secondary_hint_color);
  text-align: center;
}
