.card {
  width: 100%;
}

.content {
  padding: 24px;
  text-align: left;
}

.error {
  color: var(--tgui--destructive_text_color);
}
