.centerPadding {
  align-items: center !important;
  text-align: center;
  padding: 36px;
  display: flex;
  vertical-align: center;
}

.description {
  color: var(--tgui--secondary_hint_color);
}

.center {
  text-align: center;
  align-items: center !important;
  width: 100%;
}