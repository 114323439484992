.image {
  display: block;
  object-fit: cover;
  height: 48px;
  width: 80px;
  max-height: 48px;
  max-width: 80px;
  border-radius: 8px;
  border-color: var(--tgui--divider);
  border-width: 1px;
  border-style: solid;
}
