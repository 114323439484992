.padding {
  padding: 16px;
  padding-top: 24px;
}

.content {
  padding-top: 8px;
}

.listIos {
  padding: 0;
}

.listAndroid {
  padding: 0;
  background-color: var(--tgui--section_bg_color);
}

.listAndroid > :not(:last-child) {
  margin-bottom: 0px;
}

.errorText {
  color: var(--tgui--destructive_text_color);
}