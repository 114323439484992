.centerPadding {
  align-items: center !important;
  text-align: center;
  padding: 16px;
  padding-top: 24px;
  max-width: 700px;
  gap: 8px;
  display: flex;
  flex-direction: column;
}
