.padding {
  padding: 16px;
  padding-top: 24px;
}

.list {
  display: flex;
  flex-direction: column;
}

.content {
  padding-top: 8px;
}
