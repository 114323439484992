.cost {
  color: var(--tgui--secondary_hint_color);
}

.shareBlock {
  padding-top: 8px;
  display: flex;
  align-items: center !important;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.share {
  align-items: center !important;
}

.icon {
  margin-top: 2px;
}

.shareText {
  color: var(--tgui--subtitle_text_color);
}
