.padding {
  padding: 16px;
  padding-top: 24px;
}

.blockWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
}

.loading {
  align-items: center !important;
  text-align: center;
  padding-top: 48px;
}

.list {
  display: flex;
  flex-direction: column;
}

.emptyWrapper {
  padding-top: 48px;
  width: 100%;
  text-align: center;
  gap: 24px;
  display: flex;
  flex-direction: column;
}

.empty {
  color: var(--tgui--secondary_hint_color);
  text-align: center;
}

.clearFilterWrapper {
  padding-top: 24px;
  width: 100%;
  text-align: center;
  gap: 24px;
  display: flex;
  flex-direction: column;
}