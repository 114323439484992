.padding {
  padding: 16px;
  padding-top: 24px;
}

.content {
  padding-top: 8px;
}

.after {
  color: var(--tgui--secondary_hint_color);
}

.settingsWrapper {
  padding: 16px;
}

.settings {
  font-family: monospace;
  word-break: break-all;
  font-size: 14px;
}