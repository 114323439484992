.wrapper {
  gap: 12px;
  padding: 12px 16px;
  flex-direction: column;

}

.wrapper--ios .textarea {
  padding: 16px;
  min-height: 84px;
}

.title {
  align-self: baseline;
}

.image {
  display: block;
  object-fit: cover;
  height: 48px;
  width: 80px;
  max-height: 48px;
  max-width: 80px;
  border-radius: 8px;
  border-color: var(--tgui--divider);
  border-width: 1px;
  border-style: solid;
  background-color: var(--tgui--divider);
}

.selectedImage {
  border-color: var(--tg-theme-link-color);
  background-color: var(--tg-theme-link-color);
}

.inputWrapper {
  flex: 1 1 0;
  appearance: none;
  width: 100%;
  border: none;
  color: var(--tgui--text_color);
  outline: none;
  padding: 0px;
  margin: 0px;
  gap: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.input {
  flex: 1 1 0;
  appearance: none;
  width: 100%;
  border: none;
  background: none;
  color: var(--tgui--text_color);
  outline: none;
  padding: 0px;
  margin: 0px;
  resize: none;
  min-height: 80px;
}

.input::placeholder {
  color: var(--tgui--secondary_hint_color);
}
