.wrapper {
  gap: 12px;
  padding: 12px 16px;
}

.wrapper--ios {
  min-height: 48px;
  max-height: 48px;
}

.chevron {
  color: var(--tgui--secondary_hint_color);
  pointer-events: none;
}


.input {
  flex: 1 1 0;
  appearance: none;
  width: 100%;
  border: none;
  color: var(--tgui--text_color);
  outline: none;
  padding: 0px;
  margin: 0px;
  background-color: transparent;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.input::-webkit-date-and-time-value {
  text-align: left;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.input::placeholder {
  color: var(--tgui--secondary_hint_color);
}
