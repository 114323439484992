.wrapper {
  align-content: center;
  align-items: center !important;
  text-align: center;
  width: 100%;
}

.subTitle {
  color: var(--tgui--secondary_hint_color);
}
